.form {
    width: 621px;
    background: rgba(0, 59, 86, 0.85);
    border: 5px dashed #F58020;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 508px;
    overflow-y: auto;
}

.form::-webkit-scrollbar {
    width: 5px;
}
  
.form::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}
  
.form::-webkit-scrollbar-thumb {
    background-color: #003B56;
    border-radius: 10px;
}

.wrapperHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 35px;
}

.back {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.back span {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px; 
}

.formHeading {
    font-size: 30px;
    text-align: center;
    color: #FDFDFF; 
    border-bottom: 1px solid #FDFDFF;
    width: max-content;
}

.formHeading span {
    color: #F58020;
}

.wrapperInput {
    display: flex;
    justify-content: center;
    gap: 67px;
    margin-bottom: 40px;
}

.formLable {
    display: flex;
    align-items: center;
    color: #FDFDFF; 
    font-size: 18px;
    font-weight: 700;
    display: flex;
    gap: 17px;
}

.checkboxList .formLable {
    margin-bottom: 0px;
}

.formInput {
    width: 100%;
    padding: 7px 20px;
    background: #FDFDFF;
    border-radius: 5px;
    max-width: 183px;
}
.formInput:focus {
    outline: 2px solid transparent;
}
.name, 
.phone {
    position: relative;
}

.error {
    position: absolute;
    color: red;
    font-size: 12px;
    white-space: nowrap;
}

.email {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.email .formInput {
    min-width: 260px;
}

.sendBtn {
    color: #FDFDFF;
    font-weight: 700; 
    max-width: 250px;
    width: 100%;
    outline: 5px solid #F58020;
    border-radius: 2px;
    text-align: center;
    padding: 10px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

@media screen and (max-width: 768px) {
    .form {
        width: 100%;
        min-width: initial;
        max-width: 621px;
        padding: 20px;
        justify-content: initial;
    }

    .back {
        max-width: 45px;
        transform: translateY(-15px);
    }

    .back span {
        font-size: 14px;
    }

    .formHeading {
        font-size: 25px;
        margin-bottom: 10px;
        transform: translateY(15px);
    }

    .email {
        margin-left: initial;
        margin-right: initial;
    }

    .sendBtn {
        max-width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .wrapperInput {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .formInput {
        max-width: initial;
    }
    .email {
        align-items: initial;
    }
}