.root {
    margin-top: 42px;
}

.form {
    margin-top: 30px;
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;

}

.wrapperDescription {
    max-width: 435px;
}

.title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 48px; 
    color: #FFFFFF;
}

.title span {
    color: #F58020;
}

.description {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 24px; 
}

@media screen and (max-width:1024px) {
    .form { justify-content: center; }
}

@media screen and (max-width: 768px) {
    .root {
        margin-top: 0px;
    }
}
