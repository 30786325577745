.form {
    width: 621px;
    background: rgba(0, 59, 86, 0.85);
    border: 5px solid #F58020;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 508px;
    overflow-y: auto;
}

.form::-webkit-scrollbar {
    width: 8px;
}
  
.form::-webkit-scrollbar-track {
    background-color: none;
}
  
.form::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 10px;
}

.wrapperHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
}

.back {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.back span {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px; 
}

.formHeading {
    font-size: 30px;
    text-align: center;
    color: #FDFDFF; 
    border-bottom: 1px solid #FDFDFF;
    width: max-content;
}

.formHeading span {
    color: #F58020;
}

.lineNode {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    margin-bottom: 19px;
}

.mb20 {
    margin-bottom: 9px;
    margin-top: 0px;
}

.wrapperVehicles {
    margin-bottom: 10px;
    transition: all .2s;
}

.wrapperVehicles::-webkit-scrollbar {
    width: 5px;
}
  
.wrapperVehicles::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}
  
.wrapperVehicles::-webkit-scrollbar-thumb {
    background-color: #F58020;
    border-radius: 10px;
}

.vehicles {
    justify-content: space-between;
}

.vehicles .info {
    max-width: 20%;
    width: 100%;
}

.info {
    font-size: 22px;
    font-weight: 700;
    color: #F58020;
    display: flex;
    align-content: center;
    gap: 10px;
}

.info span {
    color: #FDFDFF;
}

.sendBtn {
    color: #FDFDFF;
    font-weight: 700; 
    width: 260px;
    border: 5px solid #F58020;
    border-radius: 5px;
    text-align: center;
    padding: 12px 0;
    margin: auto;
}

/* Mobile Vheicles */
.wrapperVehiclesMobile {
    transition: all .2s;
    display: none;
    gap: 10px;
    flex-direction: column;
}

.vehicleLine {
    position: relative;
    overflow: clip;
    width: 100%;
}

.vehicleLineTitle,
.vehicleLineTitle div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

.squer {
    width: 10px;
    height: 10px;
    min-height: 10px;
    min-width: 10px;
    background-color: #F58020;
    border-radius: 20px;
}

.index {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.showedVehicleLine {
    transition: all .2s;
    height: 0;
}

.showedVehicleLine.active {
    height: 68px;
}

@media screen and (max-width: 768px) {
    .form {
        width: 100%;
        min-width: initial;
        max-width: 621px;
        padding: 20px;
        gap: 15px;
    }

    .back {
        max-width: 45px;
        transform: translateY(-15px);
    }

    .back span {
        font-size: 14px;
    }

    .wrapperHeading {
        margin-bottom: 0px;
    }

    .formHeading {
        margin-top: 10px;
        font-size: 25px;
    }

    .lineNode {
        margin-bottom: 0;
        gap: 10px;
        flex-wrap: wrap;
    }

    .info {
        font-size: 15px;
        gap: 5px;
    }

    .info span {
        font-size: 14px;
    }

    .wrapperVehicles {
        margin-top: -10px;
    }

    .wrapperVehicles .lineNode {
        flex-wrap: wrap;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--baseOrange);
    }
    
    .wrapperVehicles .lineNode:last-child {
        border-bottom: none;
    }

    .vehicles .info {
        max-width: initial;
    }

    .sendBtn {
        width: 100%;
    }

    .wrapperVehiclesMobile { 
        display: flex;
    }

    .wrapperVehicles {
        display: none;
    }
}