.form {
    width: 621px;
    background: rgba(0, 59, 86, 0.85);
    border: 5px solid #F58020;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 508px;
    overflow-y: auto;
}

.form::-webkit-scrollbar {
    width: 8px;
}
  
.form::-webkit-scrollbar-track {
    background-color: none;
}
  
.form::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 10px;
}

.wrapperHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.back {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.back span {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px; 
}

.formHeading {
    font-size: 30px;
    text-align: center;
    color: #FDFDFF; 
    border-bottom: 1px solid #FDFDFF;
    width: max-content;
}

.formHeading span {
    color: #F58020;
}

.formLable {
    display: flex;
    align-items: center;
    color: #FDFDFF; 
    font-size: 18px;
    font-weight: 700;
    display: flex;
    gap: 17px;
}

.vehicleGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 25px;
}

.wrapperFormInput {
    position: relative;
}

.formInput {
    width: 100%;
    padding: 7px 20px;
    background: #FDFDFF;
    border-radius: 5px;
    max-width: 183px;
}
.formInput:focus {
    outline: 2px solid transparent;
}
.error {
    color: red;
    position: absolute;
    left: 5px;
    bottom: -20px;
    max-width: 183px;
    font-size: 14px;
}

.editLists {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.addList {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.addList span {
    color: #FDFDFF;
    font-weight: 700;
    font-size: 20px; 
}

.removeList {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.removeList span {
    color: #FDFDFF;
    font-weight: 700;
    font-size: 20px; 
}

.selectConditions {
    margin-bottom: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.timeBlock {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

.shippingMethod {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.operableMethod {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.checkboxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.wrapperCheckbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    position: relative;
}

.checkboxList {
    display: flex;
    align-items: center;
    gap: 5px;
}

.checkboxList .formLable {
    cursor: pointer;
    margin-bottom: 0;
}

.formLable {
    white-space: nowrap;
    margin-bottom: 10px;
}

.sendBtn {
    color: #FDFDFF;
    font-weight: 700; 
    width: 260px;
    border: 5px solid #F58020;
    border-radius: 5px;
    text-align: center;
    padding: 12px 0;
    margin: auto;
}

@media screen and (max-width: 768px) {
    .form {
        width: 100%;
        min-width: initial;
        max-width: 621px;
        padding: 20px;
        height: initial;
        overflow-y: initial;
    }

    .formHeading {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .vehicleGroup {
        flex-direction: column;
        align-items: initial;
        width: 100%;
        gap: 20px;
        margin-bottom: 20px;
    }

    .removeList svg,
    .addList svg {
        max-width: 15px;
    }


    .removeList span,
    .addList span,
    .formLable {
        font-size: 15px;
    }

    .formInput {
        max-width: 100%;
    }

    .back {
        max-width: 45px;
        transform: translateY(-15px);
    }

    .back span {
        font-size: 14px;
    }

    .formHeading {
        transform: translateY(15px);
        white-space: nowrap;
    }
    .selectConditions {
        align-items: flex-start;
        justify-content: initial;
    }
    .checkboxes {
        flex-direction: column;
        align-items: start;
    }
    .wrapperCheckbox { 
        gap: 20px;
    }
    .sendBtn {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .selectConditions {
        flex-direction: column;
        width: 100%;
    }
    .timeBlock {
        width: 100%;
    }
    .wrapperCheckbox { 
        gap: 40px;
    }

}