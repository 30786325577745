.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    gap: 50px;
}

.phoneNumber,
.navBar,
.mail {
    display: flex;
    align-items: center;
}

.flex {
    display: flex;
    align-items: center;
    gap: 15px;
}

.phoneNumber,
.mail {
    gap: 16px;
    font-size: 18px;
    line-height: 24px;
    color: var(--blackIntoBlue);
    font-weight: 500;
}

.navBar {
    gap: 50px;
}

.link {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: var(--blackIntoBlueOpc);
    transition: all .2s;
}

.link:hover {
    color: #F58020;
}

.colorWhite * {
    color: #FDFDFF !important;
    letter-spacing: 1.2px;
    font-weight: 600;
    font-family: sans-serif;
    transition: all .3s;
}

.colorWhite *:hover {
    color: #F58020 !important;
}

@media screen and (max-width: 1150px) {
    .navBar,
    .header {
        gap: 25px;
    }
}

@media screen and (max-width: 1100px) {
    .phoneNumber,
    .mail {
        font-size: 14px;
        gap: 5px;
    }

    .phoneNumber svg {
        width: 20px;
    }

    .mail svg {
        width: 15px;
    }

    .navBar {
        gap: 30px;
    }
}

@media screen and (max-width: 868px) {
    .navBar,
    .header {
        gap: 15px;
    }
}

@media screen and (max-width: 788px) {
    .navBar {
        gap: 10px;
    }
}