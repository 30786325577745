.heroSection {
    padding-bottom: 30px;
    position: relative;
    background: linear-gradient(0deg, rgba(0, 59, 86, 0.40) 0%, rgba(0, 59, 86, 0.40));
}

.heroBg {
    color: transparent;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .heroSection {
        background: initial;
    }
}