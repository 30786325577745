.form {
    min-width: 621px;
    background: rgba(0, 59, 86, 0.85);
    border: 5px solid #F58020;
    border-radius: 10px;
    padding: 20px 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 508px;
}

.formHeading {
    font-size: 30px;
    text-align: center;
    color: #FDFDFF; 
    padding-bottom: 13px;
    border-bottom: 1px solid #FDFDFF;
    margin-bottom: 48px;
    padding-bottom: 13px;
}

.formHeading span {
    color: #F58020;
}

.formLable,
.toLable {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #FDFDFF; 
    font-size: 18px;
    font-weight: 700;
    display: flex;
    gap: 17px;
}

.formInput,
.toInput {
    width: 100%;
    padding: 7px 20px;
    background: #FDFDFF;
    border-radius: 5px;
}

.formInputGroup {
    margin-bottom: 30px;
}

.toInputGroup {
    margin-bottom: 45px;
}

.inputGroup {
    position: relative;
}


.error {
    color: red;
    position: absolute;
}

.button {
    margin-top: 10px;
    width: 100%;
    border: 5px solid #F58020;
    border-radius: 5px;
    color: #FDFDFF; 
    padding: 12px 25px;
}

@media screen and (max-width: 768px) {
    .form {
        width: 100%;
        min-width: initial;
        max-width: 621px;
        padding: 20px;
    }
}